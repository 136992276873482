<template>
  <draggable 
    :class="{
      'dragArea': topics.length == 0,
      'ml-4': mainClass == 'ml-4'
      }" 
    :list="topics"
    :group="{ name: 'topic' }"
    @start="setDraggingStatus(true)"
    @end="setDraggingStatus(false)"
    @change="log">
    <div 
      :class="itemClass"
      v-for="(topic, index) in topics" :key="topic.id">
      <outline-edit-title 
        :title="topic.title"
        :index="index"
        :icon="topic.type" 
        type="topic" 
        @updateTitle="updateTitle">
      </outline-edit-title>
    </div>
  </draggable>  
</template>

<script>
import draggable from "vuedraggable";

import OutlineEditTitle from '../OutlineEditTitle'

export default {
  props: {
    topics:     {required: true,  type: Array},
    parentId:   {required: true,  type: String},
    parentPath: {required: false,  type: String},
    mainClass:  {required: false, type: String},
    itemClass:  {required: false, type: String},
  },
  components: {
    draggable,
    OutlineEditTitle
  },
  methods: {
    log: function(evt) {
      const action = Object.keys(evt)[0] // выделяем характер события
      const actionData = Object.values(evt)[0] // выделяем данные элемента надо которым происходит событие

      switch(action) {
        case 'moved': 
          this.updateTopicsIndexes(actionData.newIndex)
          break
        case 'added':
          this.$store.dispatch('addTopic', {
            topic: actionData.element, 
            index: actionData.newIndex,
            sectionPath: this.parentPath}
          ).then((topicData) => {
            if (actionData.element.id != '0') {
              this.updateTopicsIndexes(actionData.newIndex)
            } else {
              this.$set(this.topics[actionData.newIndex], 'id', topicData.id);
              this.$set(this.topics[actionData.newIndex], 'path', topicData.path);
              this.$set(this.topics[actionData.newIndex], 'index', actionData.newIndex);
              this.$set(this.topics[actionData.newIndex], 'sectionPath', this.parentPath);
              this.$set(this.topics[actionData.newIndex], 'title', actionData.element.title);
              this.$set(this.topics[actionData.newIndex], 'type', actionData.element.type);
              this.$set(this.topics[actionData.newIndex], 'body', '');

              this.$store.dispatch('updateTopicsIndexes', this.topics)
            }
          })
          break
        case 'removed':
          this.$store.dispatch('updateTopicsIndexes', this.topics)
          break
        default:
          window.console.log("Ни одного действия не выявлено!")
          break
      }
    },
    updateTopicsIndexes(index) {
      this.$store.dispatch('updateTopicsIndexes', this.topics)
      this.topics[index].index = index
      this.topics[index].path = `${this.parentPath}/topics/${this.topics[index].id}`
    },
    updateTitle(newTitle, index) {
      this.topics[index].title = newTitle
      this.$store.dispatch('updateDocTitle', this.topics[index])
    },
    setDraggingStatus(status) {
      this.$store.commit('changeOutlineDraggingStatus', {status, group: {name: 'topic'}})
    }
  }
};
</script>
<style lang="scss" scoped>

.dragArea {
  min-height: 30px;
  border: 1px dashed rgba(255,255,255,0.1);
  border-radius: 3px;
  margin-left: 25px;
}

.element {
  border: 1px solid rgba(145, 145, 145, 0.4);
}



</style>