<template>
  <draggable 
    class="d-flex nav flex-column"
    tag="ul"
    :list="chapters"
    :group="{ name: 'chapter' }"
    @start="setDraggingStatus(true)"
    @end="setDraggingStatus(false)"
    @change="log">
    <li 
      v-for="(chapter, index) in chapters"
      :key="chapter.id" 
      class="sortable mx-1"> 
      <outline-edit-title 
        :title="chapter.title"
        :index="index" 
        type="chapter" 
        @updateTitle="updateTitle"/> 
      <app-section 
        :sections="chapter.sections"
        :chapterPath="chapter.path" 
        mainClass="ml-4"
        itemClass="py-2" />
    </li>
    
  </draggable>
</template>

<script>
import draggable from "vuedraggable"

import AppSection from "./Section"
import OutlineEditTitle from '../../widgets/OutlineEditTitle'

export default {
  props: {
    chapters: {
      required: true,
      type: Array
    },
    mainClass: {
      required: false,
      type: String
    },
    itemClass: {
      required: false,
      type: String
    }
  },
  data: () => ({
    disaledEdit: true
  }),
  components: {
    draggable,
    AppSection,
    OutlineEditTitle
  },
  methods: {
    log: function(evt) {
      const action = Object.keys(evt)[0] // выделяем характер события
      const actionData = Object.values(evt)[0] // выделяем данные элемента надо которым происходит событие
      
      switch(action) {
        case 'moved': 
          this.updateChaptersIndexes(actionData.newIndex)
          break
        case 'added':
          this.$store.dispatch('addChapter', {chapter: actionData.element, index: actionData.newIndex}).then((chapterData) => {
            this.$set(this.chapters[actionData.newIndex], 'id', chapterData.id)
            this.$set(this.chapters[actionData.newIndex], 'path', chapterData.path)
            this.$set(this.chapters[actionData.newIndex], 'index', actionData.newIndex)
          })
          break
        case 'removed':
          // this.$store.dispatch('updateChaptersIndexes', this.chapters)
          break
        default:
          window.console.log("Ни одного действия не выявлено!")
          break
      }
    },
    updateChaptersIndexes(index) {
      this.$store.dispatch('updateChaptersIndexes', this.chapters)
      this.chapters[index].index = index
    }, 
    updateTitle(newTitle, index) {
      this.$set(this.chapters[index], 'title', newTitle)
      this.$store.dispatch('updateDocTitle', this.chapters[index]) 
    }, 
    setDraggingStatus(status) {
      this.$store.commit('changeOutlineDraggingStatus', {status, group: {name: 'chapter'}})
    }
  }
}
</script>
<style lang="scss" scoped>

.chapter {
  .btn {
    display: none;
    color: rgba(145, 145, 145, 0.4);
    border: none;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
  &:hover {
    .btn {
      display: inline;
    }
    // border: 1px solid rgba(145, 145, 145, 0.4);
  }
}

.dragArea {
  min-height: 30px;
  border: 1px dashed rgba(255,255,255,0.1);
  border-radius: 3px;
  padding-left: 10px;
}

.element {
  border: 1px solid rgba(145, 145, 145, 0.4);
}



</style>