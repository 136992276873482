<template>
  <div class="">
    <div v-if="type =='chapter'"
      class="d-flex align-items-center edit-btn">
      <input class="border-0 font-weight-bold h3 w-100 text-primary mr-auto" 
        style="font-family: 'Proxima Nova'; background: transparent;"
        type="text" 
        ref="title"
        :disabled="!editActive"
        v-model.lazy="showTitle" />
      <div class="btn btn-outline-dark btn-sm"
        @click="startEditing()">
        <i class="fas fa-pencil-alt"></i>
      </div> 
    </div>
    
    <div v-if="type =='section'"
      class="d-flex align-items-center edit-btn pr-1">
      <input class="border-0 text-secondary h4 w-100 pl-1 mr-auto" 
        style="font-family: 'Proxima Nova'; background: transparent; outline-color: rgba(145, 145, 145, 0.4)"
        type="text" 
        ref="title"
        :disabled="!editActive"
        v-model.lazy="showTitle" />
      <div class="btn btn-outline-dark btn-sm"
        @click="startEditing()">
        <i class="fas fa-pencil-alt"></i>
      </div> 
    </div>

    <div v-if="type =='topic'"
      class="d-flex align-items-center edit-btn">
      <i class="fs-1 mr-2"
        :class="{
          'far fa-file-alt': icon == 'textTopic',
          'fas fa-th-list': icon == 'tableTopic',
          'fas fa-chart-bar': icon == 'chart',
          'fas fa-th-list': icon == 'table'
        }">
      </i>
      <input class="border-0 text-800 font-weight-normal mr-auto" 
        style="font-family: 'Proxima Nova'; background: transparent; outline: none; min-width: 60%"
        type="text" 
        ref="title"
        :disabled="!editActive"
        v-model.lazy="showTitle" />
      <div class="btn btn-outline-dark btn-sm"
        @click="startEditing()">
        <i class="fas fa-pencil-alt"></i>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    index: {
      required: true,
      type: Number
    },
    icon: {
      required: false,
      type: String
    }
  },
  data: () => ({
    editActive: false,
    newTitle: 'Ntcn'
  }),
  computed: {
    showTitle: {
      get: function() {
        return this.title
      },
      set: function(newValue) {
        this.editActive = false  
        this.$emit('updateTitle', newValue, this.index)   
      }
    }
  },
  methods: {
    startEditing() {
      this.editActive = true
      setTimeout(() => {
        this.$refs.title.focus()
      }, 100)
      
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-btn {
  .btn {
    display: none;
    color: rgba(145, 145, 145, 0.4);
    border: none;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
  &:hover {
    .btn {
      display: inline;
    }
  }
}
</style>