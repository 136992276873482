<template>
  <div class="row h-100 mt-3 w-100">
    <div class="col-7 pl-5"  @dragenter="active=false">
      <chapter :chapters="currentOutline" />
    </div>
    <div class="col-5 pl-5">   
      <div v-if="!outlineDragging.status"
        class="alert alert-light mt-2" 
        style="position: fixed; width: 20%; max-width: 20rem;">
        <h6 class="alert-heading font-weight-bold border-bottom mb-2 pb-2">Изменение структуры документа</h6>
        <p class="fs--2 mb-2">
          На данной странице производится редактирования структуры бизнес-плана. 
        </p>
        <p class="fs--2 mb-2">
          Вы можете изменить располоежние как топиков, так и разделов - перетаскивая их в нужное место. 
        </p>
        <p class="fs--2 mb-2">
          Для добавления новых тематических топиков, просто перетащите из левого меню в нужно есто структуры вашего документа. 
        </p>
        <hr>
        <p class="mb-0 fs--2">Для добавления пользовательских разделов, используйте специальную вкладку меню.</p>
      </div>
      <div v-if="outlineDragging.status"
        class="d-flex flex-column alert-danger alert"
        style="position: fixed; width: 20%; height: 50vh; background: #3921377a;"
        @dragenter="active=true">
        <div class="">
            <h5 class="alert-heading font-proxima font-weight-semi-bold text-center">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              Удаление элементов 
            </h5> 
            <hr>
          </div>
        <draggable 
          class="nav flex-fill" 
          :list="deleteList"
          :group="outlineDragging.group"
          :move="checkMove"
          tag="ul"
          ghost-class="ghost"
          @change="log">
          <div class="d-flex align-items-center flex-fill">
            <p v-if="!active" 
              class="fs--1 text-center text-700">
              Если хотите убрать выбранные элементы из структуры бизнес-плана, переместите их в эту область.
            </p>
          </div>
        </draggable> 
        <div class="">
          <hr>
          <p class="mb-0 fs--1 text-center">
            <span class="font-weight-bold">Информация будет сохранена!</span><br> 
            <span style="color: #f297a88a;">Текст, таблицы и графики - будут перемещены в соотвествующие разделы меню выбора!</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import Chapter from '../components/app/widgets/test/Chapter'

export default {
  data: () => ({
    active: false,
    deleteList: [],
    scrollSettings: {},
  }),
  components: {
    Chapter,
    draggable,
  },
  mounted() {
    this.loadOutline()
  },
  computed: {
    currentOutline() {
      return this.$store.getters.currentOutline
    },
    outlineDragging() {
      return this.$store.getters.getOutlineDraggingStatus
    }
  },
  methods: {
    loadOutline() {
      if (this.currentOutline2.length == 0) {
        this.$store.dispatch('fetchCurrentOutline')
      } 
    }, 
    message() {
      this.$bvToast.toast('Toast body content', {
        title: `Variant ${null || 'default'}`,
        variant: null,
        solid: true
      })
    },
    log() {
      console.log('Закинули в зону удаления!', this.deleteList)
      
      switch (this.outlineDragging.group.name) {
        case 'chapter':
          console.log('chapter del')
          this.$store.dispatch('currentOutlineChapterRemove', this.deleteList[0])
          break;
        case 'section':
          console.log('section del', this.deleteList)
          this.$store.dispatch('currentOutlineSectionRemove', this.deleteList[0])
          break;
        case 'topic':
          console.log('topic del')
          this.$store.dispatch('currentOutlineTopicsRemove', this.deleteList)
          break;
        default:
          console.log('smth wrong!')
          break;
      }

      console.log('Обнуляем лист удаления', this.deleteList)
      this.deleteList = []

    },
    checkMove: function(evt) {
      console.log(evt)
      return (evt.draggedContext.element.name!=='apple');
    }
  }

}
</script>

<style lang="scss" scoped>

li {
  &.ghost {
  list-style: none !important;
  background: red !important;
  h2 {
    color: red !important;
  }
}
}

</style>>

