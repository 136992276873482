<template>
  <draggable
    :class="{'dragArea': sections.length == 0}" 
    :list="sections"
    :group="{ name: 'section' }"
    @start="setDraggingStatus(true)"
    @end="setDraggingStatus(false)"
    @change="log">                  
    <div :class="itemClass"
      v-for="(section, index) in sections" :key="section.id">
      <outline-edit-title 
        :title="section.title"
        :index="index" 
        type="section"
        @updateTitle="updateTitle"/>  
      <topic 
        :topics="section.topics"
        :parentId="section.id"
        :parentPath="section.path"
        mainClass="ml-4"
        itemClass="pl-3 pr-1 py-1 mb-1 outline-edit-topic" />
    </div>
  </draggable>
</template>
<script>
import draggable from "vuedraggable"

import Topic from './Topic'
import OutlineEditTitle from '../../widgets/OutlineEditTitle'

export default {
  props: {
    sections:     { required: true,   type: Array},
    chapterPath:  { required: false,  type: String},
    mainClass:    { required: false,  type: String},
    itemClass:    { required: false,  type: String},
  },
  data: () => ({
  }),
  components: {
    draggable,
    Topic,
    OutlineEditTitle
  },
  methods: {
    log: async function(evt) {
      const action = Object.keys(evt)[0] // выделяем характер события
      const actionData = Object.values(evt)[0] // выделяем данные элемента надо которым происходит событие

      console.log(evt)

      switch(action) {
        case 'moved': 
          this.updateSectionsIndexes(actionData.newIndex)
          break
        case 'added':
          await this.$store.dispatch('addSection', {
            section: actionData.element,
            index: actionData.newIndex, 
            chapterPath: this.chapterPath}
          ).then( (sectionData) => {
            if (actionData.element.id != '0') {
              this.updateSectionsIndexes(actionData.newIndex)
            } else {
              this.$set(this.sections[actionData.newIndex], 'id', sectionData.id)
              this.$set(this.sections[actionData.newIndex], 'path', sectionData.path)
              this.$set(this.sections[actionData.newIndex], 'index', actionData.newIndex)
              console.log(this.sections[actionData.newIndex])
            }
            
          })
          break
        case 'removed':
          this.$store.dispatch('updateSectionsIndexes', this.sections)
          break
        default:
          window.console.log("Ни одного действия не выявлено!")
          break
      }
    },
    updateSectionsIndexes(index) {
      this.$store.dispatch('updateSectionsIndexes', this.sections)
      this.sections[index].index = index
      this.sections[index].path = `${this.chapterPath}/sections/${this.sections[index].id}`
    },
    updateTitle(newTitle, index) {
      this.sections[index].title = newTitle
      this.$store.dispatch('updateDocTitle', this.sections[index]) 
    },
    setDraggingStatus(status) {
      this.$store.commit('changeOutlineDraggingStatus', {status, group: {name: 'section'}})
    }
  }
}
</script>
<style lang="scss" scoped>

.section {
  .btn {
    display: none;
    color: rgba(145, 145, 145, 0.4);
    border: none;
    &:hover {
      color: rgb(145, 145, 145);
    }
  }
  &:hover {
    .btn {
      display: inline;
    }
    // border: 1px solid rgba(145, 145, 145, 0.4);
  }
}

.dragArea {
  min-height: 30px;
  border: 1px dashed rgba(255,255,255,0.1);
  border-radius: 3px;
  margin-bottom: 25px;
}

.element {
  border: 1px solid rgba(145, 145, 145, 0.4);
}



</style>